import { updateFieldsHistory } from 'actions/actionCreators'
import { actionTypes } from 'actions/actionTypes'
import { useWidget } from 'context/widget.context'
import { useDispatch } from 'react-redux'
import LazyLoad from 'utils/lazy-load'

// lazy import to decrease production size
const Chat = LazyLoad({ loader: () => import('./chat') })

export default function WidgetMapper({ widgetType }) {
  const { formData } = useWidget()
  if (!formData) return null

  const dispatch = useDispatch()

  const variableFields = formData.variableFields

  if (variableFields.length > 0) {
    const initialValues = variableFields.reduce(
      (prev, current) => ({ ...prev, [current.slug]: [] }),
      {}
    )
    dispatch(updateFieldsHistory(initialValues))
  }
  dispatch({ type: actionTypes.LOADED_FORM, formData })
  dispatch({ type: actionTypes.END_LOADING })

  const mapWidgetToComponent = () => {
    switch (widgetType) {
      case 'chatbot':
        return Chat
      default:
        return Chat
    }
  }
  const Widget = mapWidgetToComponent()
  return <Widget />
}
