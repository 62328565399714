import React from 'react'
import equals from 'ramda/src/equals'
import queryString from 'query-string'
import { useWatch } from 'react-hook-form'
import { setGeneralDataToSubmit } from '../actions/actionCreators'
import { useInterval } from '../hooks/useInterval'
import { DRAFT_TOKEN_KEY } from '../utils/types'
import useMountEffect from '../hooks/useMountEffect'

const INTERVAL_TIME = 30000 // 30 seconds

export default function AutoSave({
  control,
  saveForm,
  formState: { isDirty, isSubmitting },
  initialData = {},
  isFormSubmitted,
  submitCode
}) {
  // Local States _______________
  const [savedData, setSavedData] = React.useState({})

  // Form values ________________
  const watchedData = useWatch({
    control,
    defaultValues: initialData,
  })

  // const [location, navigate] = useLocation()

  useMountEffect(() => {
    const searchParams = queryString.parse(window.location.search) || {}
    if (searchParams[DRAFT_TOKEN_KEY]) {
      dispatch(
        setGeneralDataToSubmit({ submit_code: searchParams[DRAFT_TOKEN_KEY] })
      )
    }
  })

  React.useEffect(() => {
    setSavedData(initialData)
  }, [initialData])

  const isFormDirtyAfterLastSave = React.useMemo(() => {
    if (!equals(savedData, watchedData) && isDirty) {
      return true
    }
    return false
  }, [watchedData, savedData, isDirty])

  const setDraftParam = (draftCode) => {
    const searchParams = queryString.parse(window.location.search) || {}
    if (draftCode && !searchParams[DRAFT_TOKEN_KEY]) {
      searchParams[DRAFT_TOKEN_KEY] = draftCode
      // navigate(`${location}?${queryString.stringify(searchParams)}`)
    }
  }

  const saveData = () => {
    if (
      isFormDirtyAfterLastSave &&
      !isSubmitting &&
      !isFormSubmitted
    ) {
      saveForm(submitCode).then((res) => {
        setSavedData((prevData = {}) => ({ ...prevData, ...res.row?.data }))
        setDraftParam(res.row?.submit_code)
        if (!submitCode || submitCode !== res.row?.submit_code) {
          dispatch(
            setGeneralDataToSubmit({ submit_code: res.row?.submit_code })
          )
        }
      })
    }
  }

  useInterval(() => {
    saveData()
  }, INTERVAL_TIME)

  return <></>
}
