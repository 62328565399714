import habitat from "preact-habitat";
import Widget from "./components/chatbot";
import axiosConfig from './api/config/axios.config'

//
// ─── INITIAL SETUP ──────────────────────────────────────────────────────────────
//
axiosConfig()

//
// ─── INITIAL WIDGET & PROPS ──────────────────────────────────────────────────────────────
//

const _habitat = habitat(Widget);

_habitat.render({
  selector: '[data-widget-form="formaloo-widget"]',
  clean: true
});

window.onload = function () {
  const el = document.getElementById('formaloo-chatbot-container');
  document.body.appendChild(el);
}

