import { useRef, useState } from "preact/hooks"

const useResponse = () => {
    let tempList = useRef([])
    const [responseList, updateResponseList] = useState([])
    const [preStep, setPreStep] = useState(0)

    const fillResponse = ({ newUI, currentStep }) => {
        const lastIndex = tempList.current.length - 1;
        if (lastIndex >= 0) {
            const lastItem = tempList.current[lastIndex];
            if (lastItem.field_slug === newUI.field_slug) {
                tempList.current = tempList.current.filter(r => r.field_slug !== newUI.field_slug)
            }
        }

        tempList.current.push(newUI)

        if (preStep !== currentStep) {
            updateResponseList(tempList.current)
            setPreStep(currentStep)
        }

    }
    return ({
        responseList, fillResponse
    })
}
export default useResponse