import { useState } from 'react'
import { useFormWidget } from 'context/form.context'
import { isNumber } from '../../utils/helpers'
import {
  afterOrOnDate,
  beforeDate,
  beforeOrOnDate,
  checkEqual,
  contains,
  endsWith,
  greaterThan,
  greaterThanEqual,
  lowerThan,
  lowerThanEqual,
  onDate,
  startsWith,
  afterDate,
  isAnswered,
  checkAlways,
} from './calculationFn'

const useLogic = (getValues) => {
  const [passedFields, updatePassedFields] = useState([])

  const getFieldValue = (fieldName) => getValues(fieldName)

  const isTheConditionsMet = ({ when }) => {
    const fields = when.args.filter((arg) =>
      ['field', 'variable'].includes(arg.type)
    )
    const desiredAnswer = when.args.find((arg) =>
      ['constant', 'choice'].includes(arg.type)
    )

    const calculationInputs = {
      desiredAnswer: isNumber(desiredAnswer?.value)
        ? { ...desiredAnswer, value: Number(desiredAnswer.value) }
        : desiredAnswer,
      fields,
      getFieldValue,
    }

    switch (when.operation) {
      case 'equal':
      case 'is':
        return checkEqual(calculationInputs)
      case 'not_equal':
      case 'is_not':
        return !checkEqual(calculationInputs)
      case 'gt':
        return greaterThan(calculationInputs)
      case 'gte':
        return greaterThanEqual(calculationInputs)
      case 'lt':
        return lowerThan(calculationInputs)
      case 'lte':
        return lowerThanEqual(calculationInputs)
      case 'contains':
        return contains(calculationInputs)
      case 'not_contains':
        return !contains(calculationInputs)
      case 'starts_with':
        return startsWith(calculationInputs)
      case 'ends_with':
        return endsWith(calculationInputs)
      case 'on':
        return onDate(calculationInputs)
      case 'not_on':
        return !onDate(calculationInputs)
      case 'before':
        return beforeDate(calculationInputs)
      case 'after':
        return afterDate(calculationInputs)
      case 'before_or_on':
        return beforeOrOnDate(calculationInputs)
      case 'after_or_on':
        return afterOrOnDate(calculationInputs)
      case 'is_answered':
        return isAnswered(calculationInputs)
      case 'always':
      case 'otherwise':
        return checkAlways(calculationInputs)
      case 'and':
        return andTheConditions(when.args)
      case 'or':
        return orTheConditions(when.args)
      default:
        return false
    }
  }

  const andTheConditions = (args = []) => {
    // 'and' operation contains multiple args and condition
    return args.every((arg) => isTheConditionsMet({ when: arg }))
  }
  const orTheConditions = (args = []) => {
    // 'or' operation contains multiple args and condition
    return args.some((arg) => isTheConditionsMet({ when: arg }))
  }

  const passTheField = (fieldSlug) => {
    // if the field has been never passed , add it is the last one then pass the field to list
    if (!passedFields.find((field) => field === fieldSlug)) {
      updatePassedFields([...passedFields, fieldSlug])
      return
    }
    // if the field has been already passed, find it and remove all the next fields in the list
    updateLastPassedField(fieldSlug)
  }

  const updateLastPassedField = (fieldSlug) => {
    const theFieldIndex = passedFields.findIndex((field) => field === fieldSlug)
    if (theFieldIndex > -1) {
      passedFields.splice(
        theFieldIndex + 1,
        passedFields.length - theFieldIndex + 2
      )
    }
  }

  return {
    passedFields,
    passTheField,
    updateLastPassedField,
    isTheConditionsMet,
  }
}

export default useLogic
